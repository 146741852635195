@import 'src/styles/variables.scss';

.main {
    color: $white;
    background-attachment: $black;

    .container {
        width: calc(100% - 40px); // (100% - margin * 2
        margin:20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }   
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main {
        .container {
            .containerRanking {
                width:90vw;
                margin:0 auto;
                .title1 {
                    font-size: 20px;
                }
                .colOverflow {
                    overflow: scroll;
                }
                .colFixed, .colOverflow {
                    .tableRanking {
                        max-width:100%;
                        
                        .theadRanking {
                            .thRanking {
                                font-size: 14px;
                            }
                        }
                        .tbodyRanking {
                            .trtbodyRanking {
                                .tdRanking {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                
            }
            .thursday {
                width:90vw;
                margin:0 auto;
                padding:0;
                .title1 {
                    font-size: 20px;
                }
                .title2 {
                    font-size: 20px;
                }
                .registrer {
                    width:100%;
                    .formContact {
                        width:100%;
                        margin:0;
                        .formItem {
                            margin-bottom: 10px;
                            input {
                                line-height:40px;
                                padding:0;
                            }
                        }
                    }
                }
            }
        }
    }
}