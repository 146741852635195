@import 'src/styles/variables.scss';

.main {
    width: 100%;
    

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:0 10px;
        padding: 20px;
        border-top: 1px solid $gray;

        .socialNetwork {
            display: flex;
            flex-direction: row;
            justify-content: center;
            list-style: none;
            margin-bottom: 15px;

            .socialItem + .socialItem {
                margin-left: 10px;
            }

            .socialItem {
                .socialIcon {
                    border: 1px solid $white;
                    border-radius: 50%;
                }
            }

            .noMinor {
                width:50px;
                height: 50px;
            }
        }

        .legacies {
            display: flex;
            flex-direction: row;
            justify-content: center;
            list-style: none;
            text-decoration: none;
            margin:20px 0;

            .legacyItem + .legacyItem {
                margin-left: 10px;
            }

            .legacyItem {
                a {
                    text-decoration: none;
                    color: $white;
                    font-size: small;
                }:hover {
                    color: $gray;
                }
            }
        }

        .copyright {
            text-align: center;
        }
    }
}