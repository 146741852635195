@import 'src/styles/variables.scss';

.main {
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: 50px;

  .container {
    width:calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top:10px;
    margin:0 10px;
    border-bottom: 1px solid $gray;

    .logo {
      height: 70px;
      cursor: pointer;
      margin:0 0 10px 20px;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main {
    .container {
      flex-direction: row;
      align-items: space-between;
      margin:0 10px;
     
      .logo {
        margin: 10px 0;
      }
    }
  }
}

