@import 'src/styles/variables.scss';
.red {
    color: $red;
  }
.main {
    height: auto;
    margin-bottom: 30px;
    .container {
        width:calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin:20px 30px;

        .title {
            text-align: center;
            margin:30px 0;
            font-size: 26px;
            color: $red;

            .red {
                color: $red;
            }
        }

        .formContact {
            width:35%;
            margin:auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .formItem {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 20px;

                label {
                    font-size: 14px;
                    color: $white;
                    margin-bottom: 10px;
                }

                input {
                    width:100%;
                    border-radius: 10px;
                    line-height:50px;
                    border: 1px solid $white;
                    background: none;
                    padding: 0 15px;
                    color: $white;
                }

                input::placeholder {
                    font-size:14px;
                    color: $white;
                }
                
                textarea {
                    width: 100%;
                    border: 1px solid $white;
                    background: none;
                    padding: 10px 15px;
                    border-radius: 10px;
                    height: 100px;
                    color: $white;
                }
                
                .button62 {
                    background: linear-gradient(to bottom right, rgb(214, 7, 7), #050505);
                    border: 0;
                    border-radius: 12px;
                    color: $white;
                    cursor: pointer;
                    display: inline-block;
                    font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 2.5;
                    outline: transparent;
                    padding: 0 1rem;
                    text-align: center;
                    text-decoration: none;
                    transition: box-shadow .2s ease-in-out;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                    white-space: nowrap;
                    margin-right:10px;
                  }
                  .button62:hover {
                    box-shadow: 0 0 .25rem rgb(73, 3, 3), -.125rem -.125rem .50rem rgb(149, 4, 4), .125rem .125rem .50rem rgba(203, 4, 4, 0.5);
                    cursor: pointer;
                  }
            }
        }  
    } 
}

.blockSubmit {
    padding-left:20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main {
        flex-direction: column;
        align-items: center;
        .container {
            width:95%;
            margin: auto;
            flex-direction: column;
            .title {
                margin: 20px 0;
            }
            .formContact {
                width: 100%;
                margin:auto;

                .formItem {
                    margin-bottom: 10px;

                    input, textarea {
                        padding: 10px;
                        width: calc(100% - 20px);
                    }
                    
                }
            }
        }
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
//  @media only screen and (min-width: 600px) {...}
 
 /* Medium devices (landscape tablets, 768px and up) */
//  @media only screen and (min-width: 768px) {...}
 
 /* Large devices (laptops/desktops, 992px and up) */
//  @media only screen and (min-width: 992px) {...}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
//  @media only screen and (min-width: 1200px) {...} 