@import 'src/styles/variables.scss';

.nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    text-decoration: none;

    .navItem + .navItem {
        margin-left: 20px;
    }

    .navItem {
        a {
            text-decoration: none;
            color: $white;
        }:hover {
            color: $gray;
        }
    }

    .navItem:last-child {
        align-self: flex-end;

        a {
            background: linear-gradient(to bottom right, rgb(214, 7, 7), #050505);
            border: 0;
            border-radius: 12px;
            color: $white;
            cursor: pointer;
            display: inline-block;
            font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 2.5;
            outline: transparent;
            padding: 0 1rem;
            text-align: center;
            text-decoration: none;
            transition: box-shadow .2s ease-in-out;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            white-space: nowrap;
            margin-right:10px;
        }
        a:hover {
            box-shadow: 0 0 .25rem rgb(73, 3, 3), -.125rem -.125rem .50rem rgb(149, 4, 4), .125rem .125rem .50rem rgba(203, 4, 4, 0.5);
            cursor: pointer;
        }
    }
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .nav {
        flex-direction: column;
        align-items: baseline;
        margin: 10px 0 0 10px;
        .navItem + .navItem {
            margin-left: 0;
            margin-top: 0px;
        }
        .navItem:last-child {
            align-self: flex-start;
        }
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
//  @media only screen and (min-width: 600px) {...}
 
 /* Medium devices (landscape tablets, 768px and up) */
//  @media only screen and (min-width: 768px) {...}
 
 /* Large devices (laptops/desktops, 992px and up) */
//  @media only screen and (min-width: 992px) {...}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
//  @media only screen and (min-width: 1200px) {...} 