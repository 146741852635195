@import 'src/styles/variables.scss';

.title1 {
    text-align: center;
    margin:30px 0;
    font-size: 26px;
    color: $red;
}
.text1 {
    text-align: center;
    margin:30px 0;
    font-size: 16px;
    color: $white; 
}
.legend {
    width:60%;
    margin:auto;
    display: flex;
    flex-direction: row;
    justify-content:center;
    
    p {
        margin: 0 10px;
        font-size: 16px;
        color: $white;
    }
}

.legendPoint {
    width:60%;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin-top: 10px;
    
    p {
        text-align: center;
        margin: 0 10px;
        font-size: 16px;
        color: $white;
    }
}

.legendSandwich {
    width:60%;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
    margin-top: 10px;
    
    h3 {
        Color: $red;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    p {
        text-align: center;
        margin: 5px 10px;
        font-size: 16px;
        color: $white;

        span {
            font-weight: bold;
        }
    }
}
.containerRanking {
    width: 60vw;
   display:flex;
   flex-direction: row;
   justify-content: center;
   margin:auto;

    .colFixed, .colOverflow {
        
        .tableRanking {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            margin: 20px 0;

            .theadRanking {
                width: 100%;
                background: $red;
                border: 0;    
                border-radius: 10px 10px 0 0;
                padding: 10px 0;
                

                .thRanking {
                    width: 100%;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: bold;
                    color: $white;
                    height:30px;
                }
            }

            .tbodyRanking {
                width: 100%;

                .trtbodyRanking {
                    width: 100%;
                    padding: 10px 0;
                    border-bottom: 1px solid rgba(97, 6, 6, 0.5);
                    box-shadow: 1px 1px 10px rgba(18, 18, 18, 0.5);
                    .left {
                        text-align:left;
                    }
                    .tdRanking {
                        width: auto;
                        padding: 0 5px;
                        font-size: 14px;
                        line-height: 30px;
                        color: $white;
                        text-align: center;
                        

                        img {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .containerRanking {
        width:90vw;
        margin:0 auto;
        .title1 {
            font-size: 20px;
        }
        .colOverflow {
            overflow: scroll;
        }
        .colFixed, .colOverflow {
            .tableRanking {
                max-width:100%;
                
                .theadRanking {
                    .thRanking {
                        font-size: 14px;
                    }
                }
                .tbodyRanking {
                    .trtbodyRanking {
                        .tdRanking {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        
    }
    .thursday {
        width:90vw;
        margin:0 auto;
        padding:0;
        .title1 {
            font-size: 20px;
        }
        .title2 {
            font-size: 20px;
        }
        .registrer {
            width:100%;
            .formContact {
                width:100%;
                margin:0;
                .formItem {
                    margin-bottom: 10px;
                    input {
                        line-height:40px;
                        padding:0;
                    }
                }
            }
        }
    }
}
