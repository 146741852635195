@import 'src/styles/variables.scss';

.navMobile {
    position:fixed;
    bottom: 0;
    margin:0 -10px;
    padding:0;
    width: 100vw;
    line-height: 75px;
    background: $black;

    .nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        list-style: none;
        text-decoration: none;
        margin:0 10px;
    
        .navItem + .navItem {
            margin-left: 20px;
        }
    
        .navItem {
            a {
                text-decoration: none;
                color: $white;
            }:hover {
                color: $gray;
            }
        }
        
    }
    
}

 