@import 'src/styles/variables.scss';

.thursday {
    width: 60%;
    margin:auto;

    .title1 {
        text-align: center;
        margin:30px 0;
        font-size: 26px;
        color: $red;
    }

    .title2 {
        text-align: center;
        margin:30px 0;
        font-size: 26px;
        color: $white;
    }

    .registrer {
        width: 100%;

        .formContact {
            width:65%;
            margin:30px auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
          
            .red {
                color: $red;
            }
            
            .formItem {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 20px;
    
                label {
                    font-size: 14px;
                    color: $white;
                    margin-bottom: 10px;
                }
                input {
                    width:100%;
                    border-radius: 10px;
                    line-height:50px;
                    border: 1px solid $white;
                    background: none;
                    padding: 0 15px;
                    color: $white;
                }
                input::placeholder {
                    font-size:14px;
                    color: $white;
                }
                
                .button62 {
                    background: linear-gradient(to bottom right, rgb(214, 7, 7), #050505);
                    border: 0;
                    border-radius: 12px;
                    color: $white;
                    cursor: pointer;
                    display: inline-block;
                    font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 2.5;
                    outline: transparent;
                    padding: 0 1rem;
                    text-align: center;
                    text-decoration: none;
                    transition: box-shadow .2s ease-in-out;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                    white-space: nowrap;
                    margin-right:10px;
                }
                .button62:hover {
                    box-shadow: 0 0 .25rem rgb(73, 3, 3), -.125rem -.125rem .50rem rgb(149, 4, 4), .125rem .125rem .50rem rgba(203, 4, 4, 0.5);
                    cursor: pointer;
                }
            }
            .blockSubmit {
                padding-left:20px;
            }
        }
    }  
}

@media only screen and (max-width: 600px) {
    .thursday {
        width: 100%;
        margin:auto;

        .title1 {
            text-align: center;
            margin:30px 0;
            font-size: 26px;
            color: $red;
        }

        .title2 {
            text-align: center;
            margin:30px 0;
            font-size: 26px;
            color: $white;
        }

        .text1 {
            text-align: center;
            margin:30px 0;
            font-size: 16px;
            color: $white;
        }

        .registrer {
            width: 100%;

            .formContact {
                width:100%;
                margin:30px auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
              
                .red {
                    color: $red;
                }
                
                .formItem {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-bottom: 20px;
        
                    label {
                        font-size: 14px;
                        color: $white;
                        margin-bottom: 10px;
                    }
                    input {
                        width:calc(100% - 30px);
                        border-radius: 10px;
                        line-height:50px;
                        border: 1px solid $white;
                        background: none;
                        padding: 0 15px;
                        color: $white;
                    }
                    input::placeholder {
                        font-size:14px;
                        color: $white;
                    }
                    
                    .button62 {
                        background: linear-gradient(to bottom right, rgb(214, 7, 7), #050505);
                        border: 0;
                        border-radius: 12px;
                        color: $white;
                        cursor: pointer;
                        display: inline-block;
                        font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 2.5;
                        outline: transparent;
                        padding: 0 1rem;
                        text-align: center;
                        text-decoration: none;
                        transition: box-shadow .2s ease-in-out;
                        user-select: none;
                        -webkit-user-select: none;
                        touch-action: manipulation;
                        white-space: nowrap;
                        margin-right:10px;
                    }
                    .button62:hover {
                        box-shadow: 0 0 .25rem rgb(73, 3, 3), -.125rem -.125rem .50rem rgb(149, 4, 4), .125rem .125rem .50rem rgba(203, 4, 4, 0.5);
                        cursor: pointer;
                    }
                }
                .blockSubmit {
                    padding-left:20px;
                }
            }
        }
    }
}