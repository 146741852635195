@import 'src/styles/variables.scss';
.main {
    color:$white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container {
        width:calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin:auto;

        .club {
            width:60%;
            margin:auto;

            .title {
                text-align: center;
                margin:30px 0;
                font-size: 26px;
                color: $red;
            }

            .paragraph {
                width:99%;
                margin:auto;
                font-size:14px;
                margin:5px 0;
                line-height: 1.5em;
                text-align: left;
            }
        }

        .staff {
            width:60%;
            margin:auto;
            
            .title {
                text-align: center;
                margin:30px 0;
                font-size: 26px;
                color: $red;
            }

            .containerStaff {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }   
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .main {
        flex-direction: column;
        align-items: center;
        .container {
            flex-direction: column-reverse;
            align-items: center;
            .club {
                width:100%;
            }
            .staff {
                width:100%;

                .containerStaff {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
//  @media only screen and (min-width: 600px) {...}
 
 /* Medium devices (landscape tablets, 768px and up) */
//  @media only screen and (min-width: 768px) {...}
 
 /* Large devices (laptops/desktops, 992px and up) */
//  @media only screen and (min-width: 992px) {...}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
//  @media only screen and (min-width: 1200px) {...} 