@import 'src/styles/variables.scss';

.card {
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content:left;
    align-items: center;
    margin: 10px 0;

    .pictures {
        width: 200px;
        height: 200px;
            

        .photo {
            width: 200px;
            height: 200px;
            // border-radius: 50%;
            // border:1px solid $gray;
        }
    }

    .info {
        .name {
            font-size: 16px;
            font-weight: bold;
            line-height: 2;
        }

        .function {
            font-size: 14px;
            font-weight: bold;
            line-height: 2;
        }

        .expression {
            font-size: 14px;
            font-weight:lighter;
            margin-top:30px;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   .card {
       width: 100%;
       flex-direction: column;
       align-items: center;
       .pictures {
           width: 150px;
           height: 150px;
           margin-bottom: 20px;
           .photo {
               width: 150px;
               height: 150px;
           }
       }
       .info {
           text-align: center;
           .name {
               font-size: 14px;
               line-height: 1.5;
           }
           .function {
               font-size: 12px;
               line-height: 1.5;
           }
           .expression {
               font-size: 12px;
               line-height: 1.5;
           }
       }
   }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
//  @media only screen and (min-width: 600px) {...}
 
 /* Medium devices (landscape tablets, 768px and up) */
//  @media only screen and (min-width: 768px) {...}
 
 /* Large devices (laptops/desktops, 992px and up) */
//  @media only screen and (min-width: 992px) {...}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
//  @media only screen and (min-width: 1200px) {...} 