@import 'src/styles/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
}
.affiche {
    width: 49%;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 20px;
    height: auto;
    text-align:center;
    background-color: none;
    border-radius:10px;
    position:relative;

    .full {
      padding: 5px 10px;
      background-color: $red;
      position:absolute;
      top: 30%;
      left: 25%;
      rotate: 30deg;

      .textfull {
        font-size: 2rem;
        color: $white;
        font-weight: bold;
      }
    }


    

    .noMinor {
      width:50px;
      height:50px;
    }

    .title {
      text-align: center;
      margin:30px 0;
      font-size: 26px;
      color: $white;
      
    }
    .red {
      color: $red;
    }
    .date {
      font-size: 1.1em;
      margin: -30px 0 15px 0;
      color: $white;
    }
    .open {
      font-size:0.8em;
      color: $white;
    }
    .details {
      font-size:0.5em;
      color: $white;
    }
    address {
      font-size:0.7em;
      font-style:oblique;
      color: $white;
    }

    @keyframes rotating {
      from {
        transform: rotateY(0deg);
      }
      to {
        transform: rotateY(360deg);
      }
    }
    .rotating {
      // animation: rotating 2s infinite linear;
      width:200px;
      z-index: -100 !important;
    }
    .separator {
      width:20%;
      height:3px;
      background: rgb(82,81,82);
      background: linear-gradient(90deg, rgba(82,81,82,1) 0%, rgba(252,250,250,1) 50%, rgba(82,81,82,1) 100%);
      margin: 10px auto;
    }
  }
   
  .registrer {
    width: 49%;
    .formContact {
      width:65%;
      margin:30px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .full {
        width: 60%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $red;
        padding: 10px;

        .textfull {
          font-size: 1.5rem;
          color: $white;
          font-weight: bold;
        }

        .textfull2 {
          font-size:1rem;
          color: $white;
        }
      }

      .title {
        text-align: center;
        margin:30px 0;
        font-size: 26px;
        color: $white;
      }
      .red {
        color: $red;
      }

      .formItemInline {
        display: flex;
        justify-content: baseline;
        margin-bottom: 20px;
        flex-direction: row;
      }
      .formItem {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 20px;



          label {
              font-size: 14px;
              color: $white;
              margin-bottom: 10px;
          }
          input {
              width:100%;
              border-radius: 10px;
              line-height:50px;
              border: 1px solid $white;
              background: none;
              padding: 0 15px;
              color: $white;
          }
          input::placeholder {
              font-size:14px;
              color: $white;
          }
          textarea {
              width: 100%;
              border: 1px solid $white;
              background: none;
              padding: 10px 15px;
              border-radius: 10px;
              height: 100px;
              color: $white;
          }
          .button62 {
              background: linear-gradient(to bottom right, rgb(214, 7, 7), #050505);
              border: 0;
              border-radius: 12px;
              color: $white;
              cursor: pointer;
              display: inline-block;
              font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 2.5;
              outline: transparent;
              padding: 0 1rem;
              text-align: center;
              text-decoration: none;
              transition: box-shadow .2s ease-in-out;
              user-select: none;
              -webkit-user-select: none;
              touch-action: manipulation;
              white-space: nowrap;
              margin-right:10px;
          }
          .button62:hover {
              box-shadow: 0 0 .25rem rgb(73, 3, 3), -.125rem -.125rem .50rem rgb(149, 4, 4), .125rem .125rem .50rem rgba(203, 4, 4, 0.5);
              cursor: pointer;
          }
      }
      .blockSubmit {
        padding-left:20px;
    }
    }
  }  

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }  
  .affiche {
      width: 100%;
      .title {
        font-size: 20px;
      }

      .full {
        width: 100%;
        left: -10%;
        .textfull {
          font-size: 1.5rem;
        }
        .textfull2 {
          font-size:0.8rem;
        }
      }

    .rotating {
      max-width: 100%;
    }
    }
    .registrer {
      width: 100%;
      .formContact {
        width:100%;
        .full {
          width: 100%;
          .textfull {
            font-size: 1.2rem;
          }
          .textfull2 {
            font-size:0.8rem;
          }
        }
        .formItem {
          margin-bottom: 10px;

          input, textarea{
              width: calc(100% - 30px);
          }
      }
      }
    
    }}